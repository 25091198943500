<template>
    <v-card
        class="card-border d-flex flex-column justify-start align-center elevation-0 border-radius-1 pb-0 w-100"
    >
        <v-container class="mb-5">
            <v-col cols="12 pa-0">
                <v-row class="text-capitalize">
                    <v-col
                        cols="2"
                        class="primary--text font-size-18"
                        :style="{ color: `${mainColor} !important` }"
                        >{{ $t('courses') }}</v-col
                    >
                    <v-col cols="1" class="grey--text darken-2 font-size-14 px-0">{{
                        $t('correct')
                    }}</v-col>
                    <v-col cols="1" class="grey--text darken-2 font-size-14 px-0">{{
                        $t('incorrect')
                    }}</v-col>
                    <v-col cols="1" class="grey--text darken-2 font-size-14">{{
                        $t('total')
                    }}</v-col>
                    <v-col cols="1" class="grey--text darken-2 font-size-12">{{
                        $t('percentageCorrectAnswers')
                    }}</v-col>
                    <v-col cols="1" class="grey--text darken-2 font-size-12">{{
                        $t('percentageWrongAnswers')
                    }}</v-col>
                    <v-col cols="1" class="grey--text darken-2 font-size-12">{{
                        $t('home.simple_comprehesion_skills[0]')
                    }}</v-col>
                    <v-col cols="1" class="grey--text darken-2 font-size-12">{{
                        $t('home.simple_comprehesion_skills[1]')
                    }}</v-col>
                    <v-col cols="1" class="grey--text darken-2 font-size-12">{{
                        $t('home.simple_comprehesion_skills[2]')
                    }}</v-col>
                </v-row>
            </v-col>
            <v-divider class="my-0"></v-divider>
            <v-col cols="12" class="d-flex flex-column" v-if="tableData.length">
                <div v-for="(item, index) of tableData" :key="index" class="position-relative">
                    <AccuracyCourseRow
                        :row-data="item"
                        symbol="%"
                        :color="mainColor ? mainColor : color(item.accuracy)"
                        @row-action="setSelected(item.reader.id)"
                    />
                </div>
            </v-col>
            <v-col cols="12" class="d-flex flex-column text-center" v-else>
                <span class="text-center" :style="{ color: `${mainColor}` }">{{
                    $t('not_data')
                }}</span>
            </v-col>
        </v-container>
    </v-card>
</template>

<script>
import AccuracyCourseRow from './AccuracyCourseRow'

export default {
    name: 'AccuracyCourseTable',
    props: {
        tableData: { type: Array, default: () => [] },
        mainColor: { type: String, default: '' },
        symbol: { type: String, default: '%' },
    },
    components: {
        AccuracyCourseRow,
    },
    data: () => ({
        showAll: false,
        selectedIndex: null,
    }),
    methods: {
        setSelected(id = 0) {
            this.selectedIndex = id
        },
        color(value = 0) {
            if (value <= 40) return '#514773'
            if (value >= 41 && value <= 59) return '#a48b00'
            return '#24dca9'
        },
        showAllRecords() {
            document.getElementById('records-card').scrollTop = 0
            this.showAll = !this.showAll
        },
        compare(a, b) {
            if (a.accuracy < b.accuracy) {
                return 1
            }
            if (a.accuracy > b.accuracy) {
                return -1
            }
            return 0
        },
    },
}
</script>

<style scoped></style>
