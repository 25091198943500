<template>
    <v-card flat elevation="0" :loading="loading">
        <v-container class="pa-0">
            <v-row class="pa-0">
                <v-col cols="2" class="d-flex flex-column">
                    <div class="d-flex justify-start align-start flex-column w-100">
                        <span class="primary--text font-size-14 text-capitalize">{{
                            $t('resources.report_type')
                        }}</span>
                        <v-radio-group v-model="reportSelect" class="pa-0 ma-0 mt-2 w-100">
                            <v-radio
                                v-for="(course, index) of reportTypes"
                                :key="index"
                                :label="`${course.name}`"
                                :value="course.id"
                                class="radio-content primary--text py-0 w-100 text-capitalize"
                            ></v-radio>
                        </v-radio-group>
                    </div>
                    <div
                        class="d-flex justify-start align-start flex-column w-100"
                        v-if="reportSelect === STUDENT_REPORT_TYPE"
                    >
                        <span class="primary--text font-size-14 text-capitalize">{{
                            $t('courses')
                        }}</span>
                        <v-radio-group v-model="courseSelect" class="pa-0 ma-0 mt-2 w-100">
                            <v-radio
                                v-for="(course, index) of courses"
                                :key="index"
                                :label="`${course.name}`"
                                :value="course.id"
                                class="radio-content primary--text py-0 w-100"
                            ></v-radio>
                        </v-radio-group>
                    </div>
                    <div class="d-flex justify-start align-start flex-column w-100">
                        <span class="primary--text font-size-14 text-capitalize">{{
                            $t('date_range')
                        }}</span>
                        <v-menu
                            ref="menu"
                            v-model="initMenu"
                            :close-on-content-click="false"
                            :return-value.sync="initDate"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="initDate"
                                    :label="$t('starting_date')"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="initDate"
                                no-title
                                scrollable
                                @dblclick:date="dblClick"
                            >
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="initMenu = false"
                                    class="text-capitalize"
                                >
                                    {{ $t('cancel') }}
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.menu.save(initDate)"
                                    class="text-capitalize"
                                >
                                    {{ $t('select') }}
                                </v-btn>
                            </v-date-picker>
                        </v-menu>
                        <v-menu
                            ref="endmenu"
                            v-model="endMenu"
                            :close-on-content-click="false"
                            :return-value.sync="endDate"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="endDate"
                                    :label="$t('ending_date')"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="endDate"
                                no-title
                                scrollable
                                @dblclick:date="dblEndClick"
                            >
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="endMenu = false"
                                    class="text-capitalize"
                                >
                                    {{ $t('cancel') }}
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.endmenu.save(endDate)"
                                    class="text-capitalize"
                                >
                                    {{ $t('select') }}
                                </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </div>
                    <div class="mt-2 d-flex justify-center">
                        <v-btn
                            outlined
                            color="secondary"
                            class="text-capitalize"
                            @click="execute"
                            :disabled="this.reportSelect === STUDENT_REPORT_TYPE && !courseSelect"
                            >{{ $t('execute') }}</v-btn
                        >
                    </div>
                </v-col>
                <v-col cols="10">
                    <div id="print-div" v-if="accuracyData.length || accuracyCourseData.length">
                        <AccuracyTable
                            v-if="accuracyData.length"
                            :table-data="accuracyData"
                            symbol="%"
                            :use-details="false"
                        ></AccuracyTable>
                        <AccuracyCourseTable
                            v-if="accuracyCourseData.length"
                            :table-data="accuracyCourseData"
                            symbol="%"
                        ></AccuracyCourseTable>
                    </div>
                    <v-row v-else>
                        <v-col cols="12" class="text-center">
                            <span class="primary--text font-size-24">{{ $t('not_data') }}</span>
                        </v-col>
                    </v-row>
                    <div
                        class="d-flex justify-end align-end flex-row mt-4"
                        v-if="accuracyData.length || accuracyCourseData.length"
                    >
                        <v-btn
                            color="secondary"
                            outlined
                            rounded
                            class="mr-2 text-capitalize"
                            @click="print"
                            ><v-icon class="mr-2">mdi-printer</v-icon> {{ $t('print') }}</v-btn
                        >
                        <download-excel :data="jsonData" type="csv" :name="filename">
                            <v-btn color="secondary" outlined rounded class="text-capitalize"
                                ><v-icon class="mr-2">mdi-file-export-outline</v-icon
                                >{{ $t('export_csv') }}</v-btn
                            >
                        </download-excel>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import { mapState } from 'vuex'
import courseBookStats from '../../services/courseBookStats'
import AccuracyTable from '../library/core/AccuracyTable'
import AccuracyCourseTable from './core/AccuracyCourseTable'

import AnalyticEventsMixin from '@/mixins/analyticEvents' //eslint-disable-line
import { AnalyticEventTypes } from '../../constants/AnalyticEventTypes'

export const COURSE_REPORT_TYPE = 0
export const STUDENT_REPORT_TYPE = 1

export default {
    name: 'Reports',
    components: { AccuracyCourseTable, AccuracyTable },
    mixins: [AnalyticEventsMixin],
    async mounted() {
        const { reportType } = this.$route.query
        this.initDate = this.$moment().clone().startOf('month').format('YYYY-MM-DD')
        this.endDate = this.$moment().clone().endOf('month').format('YYYY-MM-DD')
        if (reportType) {
            this.getReportFromQuery(reportType)
        } else {
            await this.getReportByTeacher()
        }
    },
    computed: {
        ...mapState({
            courses: (state) => state.userData.courses,
            user: (state) => state.userData.user,
        }),
        reportTypes() {
            return [
                { value: 0, name: this.$t('courses') },
                { value: 1, name: this.$t('students') },
            ]
        },
        jsonData() {
            let result = []

            if (this.accuracyData.length)
                result = this.accuracyData.map((i) => ({
                    student: this.$utils.getFullName(i.reader),
                    corrects: i.correctAnswers,
                    total: i.totalAnswers,
                    accuracy: i.accuracy,
                    accuracyIn: i.accuracyIn,
                    accuracyLoc: i.accuracyLoc,
                    accuracyRv: i.accuracyRv,
                    incorrect: i.incorrectAnswers,
                    accuracyIncorrectAnswers: i.accuracyWrongAns,
                }))

            if (this.accuracyCourseData.length)
                result = this.accuracyCourseData.map((i) => ({
                    course: i.courseName,
                    corrects: i.correctAnswers,
                    total: i.totalAnswers,
                    accuracy: i.accuracy,
                    accuracyIn: i.accuracyIn,
                    accuracyLoc: i.accuracyLoc,
                    accuracyRv: i.accuracyRv,
                    incorrect: i.incorrectAnswers,
                    accuracyIncorrectAnswers: i.accuracyIncorrectAnswers,
                }))

            return result
        },
        filename() {
            if (this.reportSelect === COURSE_REPORT_TYPE)
                return `course-report-${this.initDate}-${this.endDate}.csv`
            // Students report without selected course
            if (this.reportSelect === STUDENT_REPORT_TYPE && !this.courseSelect) return ''

            // Students report with selected course
            const course = this.courses.find((i) => i.id === this.courseSelect)
            return `student-report-${course.name}-${this.initDate}-${this.endDate}.csv`
        },
    },
    data: () => ({
        courseSelect: null,
        reportSelect: COURSE_REPORT_TYPE,
        initMenu: false,
        initDate: '',
        endMenu: false,
        loading: false,
        endDate: '',
        accuracyData: [],
        accuracyCourseData: [],
        COURSE_REPORT_TYPE,
        STUDENT_REPORT_TYPE,
    }),
    methods: {
        dblClick(date) {
            this.$refs.menu.save(date)
            this.menu = false
        },
        dblEndClick(date) {
            this.$refs.endmenu.save(date)
            this.endMenu = false
        },
        async getReportByCourse() {
            this.loading = true
            try {
                const response = await courseBookStats
                    .getAccuracyDataByDateRange(this.courseSelect, this.initDate, this.endDate)
                    .catch((e) => console.error(e))
                this.accuracyData = response.content
            } catch (error) {
                this.$modal.error(this.$t('resources.unable_to_get_student_report'))
            } finally {
                this.loading = false
            }
        },
        async getReportByTeacher() {
            this.loading = true
            try {
                const response = await courseBookStats
                    .getSummaryAccuracyDataByDateRangeAndCourses(
                        this.user.id,
                        this.initDate,
                        this.endDate
                    )
                    .catch((e) => console.error(e))
                this.accuracyCourseData = response.content
            } catch (error) {
                this.$modal.error(this.$t('resources.unable_to_get_course_report'))
            } finally {
                this.loading = false
            }
        },
        async execute() {
            this.accuracyCourseData = []
            this.accuracyData = []
            if (this.reportSelect === STUDENT_REPORT_TYPE) await this.getReportByCourse()
            if (this.reportSelect === COURSE_REPORT_TYPE) await this.getReportByTeacher()
            this.createAnalyticEvent(AnalyticEventTypes.LoadGeneralReport)
        },
        async print() {
            this.$htmlToPaper('print-div', null, () => {
                console.debug('Printing completed or was cancelled!')
            })
        },
        /**
         * Gets the report from query string parameter
         * @param reportType type of report to get
         */
        getReportFromQuery(reportType) {
            this.reportSelect = reportType
            if (
                this.reportSelect === STUDENT_REPORT_TYPE &&
                this.courses &&
                this.courses.length > 0
            ) {
                this.courseSelect = this.courses[0].id
            }
            this.execute()
        },
    },
}
</script>

<style scoped>
.radio-content {
    border-radius: 5px;
}
</style>
