<template>
    <div @click="action">
        <v-row class="pa-0 accuracy-table-row cursor-pointer align-center">
            <v-col cols="2" class="py-2 grey--text darken-2 text-capitalize">
                <span class="ml-1">{{ rowData.courseName }}</span>
            </v-col>
            <v-col cols="1" class="py-2 grey--text darken-2 text-center">{{
                rowData.correctAnswers
            }}</v-col>
            <v-col cols="1" class="py-2 grey--text darken-2 text-center"
                >{{ rowData.incorrectAnswers }}
            </v-col>
            <v-col cols="1" class="py-2 grey--text darken-2 text-center">{{
                rowData.totalAnswers
            }}</v-col>
            <v-col cols="1" class="py-2 grey--text darken-2">{{
                `${rowData.accuracy}${symbol}`
            }}</v-col>
            <v-col cols="1" class="py-2 grey--text darken-2">{{
                `${rowData.accuracyIncorrectAnswers}${symbol}`
            }}</v-col>
            <v-col cols="1" class="py-2 grey--text darken-2 text-right">{{
                `${rowData.accuracyLoc}${symbol}`
            }}</v-col>
            <v-col cols="1" class="py-2 grey--text darken-2 text-right">{{
                `${rowData.accuracyIn}${symbol}`
            }}</v-col>
            <v-col cols="1" class="py-2 grey--text darken-2 text-right">{{
                `${rowData.accuracyRv}${symbol}`
            }}</v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'AccuracyCourseRow',
    components: {},
    props: {
        rowData: { type: Object, default: () => null },
        color: { type: String, default: '#f1ff1' },
        symbol: { type: String, default: '%' },
        seleced: { type: Boolean, default: false },
    },
    methods: {
        action() {
            this.$emit('row-action')
        },
    },
}
</script>

<style scoped></style>
